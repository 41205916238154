var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center pb-0"},[_c('h4',{staticClass:"mr-auto mb-0"},[_vm._v("تفاصيل المجهر")])]),_c('ValidationObserver',{ref:"observertelescope"},[_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputText',{attrs:{"label":"اسم المجهر","placeholder":"اختر اسم المجهر","rules":[
                        {
                          type: 'required',
                          message: 'اسم المجهر مطلوب',
                        } ],"name":"name","clearable":true},model:{value:(_vm.telescopeDto.name),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "name", $$v)},expression:"telescopeDto.name"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputSelect',{attrs:{"label":"المادة","placeholder":"اختر المادة","rules":[
                        {
                          type: 'required',
                          message: 'المادة مطلوب',
                        } ],"options":_vm.subjectsList,"name":"subjectId","clearable":true},model:{value:(_vm.telescopeDto.subjectId),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "subjectId", $$v)},expression:"telescopeDto.subjectId"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputSelect',{attrs:{"label":"السنة","placeholder":"اختر السنة","rules":[
                        {
                          type: 'required',
                          message: 'السنة إجبارية',
                        } ],"options":_vm.years,"name":"year"},model:{value:(_vm.telescopeDto.year),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "year", $$v)},expression:"telescopeDto.year"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputSelect',{attrs:{"label":"تصنيفات المجهر","placeholder":"اختر تصنيفات","rules":[
                        {
                          type: 'required',
                          message: 'اختر التصنيفات التي يكون المجهر تابع لها',
                        } ],"multiple":"","options":_vm.tagsList,"name":"categories"},model:{value:(_vm.telescopeDto.tagIds),callback:function ($$v) {_vm.$set(_vm.telescopeDto, "tagIds", $$v)},expression:"telescopeDto.tagIds"}})],1)],1)],1)],1),_c('b-card-footer',{staticClass:"d-flex"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("تعديل")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-primary","to":"/telescope"}},[_vm._v("تراجع")]),_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.onRemove()}}},[_vm._v("حذف")])],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center pb-0"},[_c('h4',{staticClass:"mr-auto mb-0"},[_vm._v("صور المجهر")]),_c('addMicroscopeSection',{ref:"microscopeSectionDialog",attrs:{"id":_vm.telescopeDto.id}})],1),_c('ValidationObserver',{ref:"sectionMicroscope"},[_c('b-card-body',{staticClass:"py-0"},[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-end",attrs:{"cols":"12"}},[_c('EKInputSelect',{staticClass:"flex-grow-1",attrs:{"label":"السؤال","placeholder":"اختر سؤال","options":_vm.telescopeDto.sections,"name":"selectedSection","textLabel":"title","clearable":true,"rules":[
                        {
                          type: 'required',
                          message: 'السؤال إجباري',
                        } ]},model:{value:(_vm.selectedSection),callback:function ($$v) {_vm.selectedSection=$$v},expression:"selectedSection"}}),(_vm.selectedSection)?_c('b-button',{staticClass:"ml-1 mb-1 p-1",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.OnDeleteSectionMicroscope(_vm.selectedSection)}}},[_c('unicon',{attrs:{"name":"trash-alt","fill":"#fff","width":"18","height":"18"}})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"4"}},[(_vm.selectedSection)?_c('EKInputImage',{ref:"fileInput",attrs:{"multiple":"","height":"150px","name":"img"},on:{"input":_vm.updateFile}},[_c('h5',[_vm._v("اسحب الملف او انقر للتحميل")])]):_vm._e()],1),_vm._l((_vm.selectedSectionDto.documents),function(doc,index){return _c('b-col',{key:index,attrs:{"cols":"3"}},[_c('div',{staticClass:"drop-container rounded overflow-hidden d-flex justify-content-center align-items-center mt-2",staticStyle:{"height":"150px","box-shadow":"0px 0px 5px 0px #0000003d","background-size":"cover","position":"relative"},style:((_vm.indexChoose == index && _vm.typeListDescription == 0
                          ? 'border: #6610f2; border-style: solid;'
                          : '') +
                        'background-image:' +
                        (doc.isDeleted
                          ? 'linear-gradient(rgb(100 0 0 / 65%), rgb(100 0 0 / 65%)),'
                          : '') +
                        'url(' +
                        _vm.$store.getters['app/domainHost'] +
                        '/' +
                        doc.path.replaceAll('\\', '/').replaceAll(' ', '%20') +
                        ');' +
                        (doc.isDeleted ? '' : 'cursor: pointer;')),on:{"click":function($event){return _vm.chooseImage(index)}}},[_c('b-button',{staticClass:"clear btn-icon border-0 mr-1",staticStyle:{"margin-top":"4px","z-index":"1","position":"absolute","top":"0px","left":"0px"},attrs:{"size":"sm","variant":"flat-secondary"},on:{"click":function($event){return _vm.toggleImage(index, doc)}}},[_c('unicon',{attrs:{"name":doc.isDeleted ? 'history-alt' : 'trash-alt',"fill":doc.isDeleted ? '#11cc22' : '#ea5455',"width":"16","height":"16"}})],1)],1)])}),_c('b-col',{attrs:{"cols":"12"}},[(
                        _vm.selectedSectionDto.documents.length > 0 &&
                        _vm.typeListDescription == 0
                      )?_c('EKInputTextarea',{attrs:{"label":" وصف الصورة","placeholder":"وصف الصورة مطلوب","name":"name"},model:{value:(_vm.selectedSectionDto.documents[_vm.indexChoose].note),callback:function ($$v) {_vm.$set(_vm.selectedSectionDto.documents[_vm.indexChoose], "note", $$v)},expression:"selectedSectionDto.documents[indexChoose].note"}}):(
                        _vm.selectedSectionDto.documents.length > 0 &&
                        _vm.typeListDescription == 1
                      )?_c('EKInputTextarea',{attrs:{"rules":[
                        {
                          type: 'required',
                          message: 'وصف الصورة مطلوب',
                        } ],"label":" وصف الصورة","placeholder":"وصف الصورة مطلوب","name":"name"},model:{value:(_vm.newDocuments[_vm.documentIndex].note),callback:function ($$v) {_vm.$set(_vm.newDocuments[_vm.documentIndex], "note", $$v)},expression:"newDocuments[documentIndex].note"}}):_vm._e()],1)],2)],1)],1),_c('b-card-footer',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.SubmitUpdateSectionMicroscope}},[_vm._v("حفظ")]),_c('b-button',{attrs:{"variant":"outline-primary","to":"../telescope"}},[_vm._v("تراجع")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }